.container {
  max-width: 1240px;
  margin: 0 auto;
  padding: 0 1rem;
}

body,
html {
  overflow-x: hidden;
}

.header {
  background: linear-gradient(#0f1210, black);
}

nav {
  background: url("../images/nav-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
  padding: 8px 15px;
}

.nav-link.active {
  color: #fed506;
}

.nav-link {
  padding: 10px 20px;
}

.nav-link::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: black;
  top: 0;
  left: 0;
  transform: skewX(-20deg);
}

.hero {
  background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url("../../assets/images/hero-bg.png");
  background-size: 50%;
  background-position: right;
  background-repeat: no-repeat;
}

.border-\[\#32BE71\] {
  border-color: #32be71;
}

.border-\[\#28B2E3\] {
  border-color: #28b2e3;
}

.border-\[\#FED506\] {
  border-color: #fed506;
}

.trusted-title {
  background: rgba(65, 65, 65, 0.3);
  padding: 10px 100px 10px 30px;
  display: inline-block;
  clip-path: polygon(0 0, 80% 0, 100% 100%, 0% 100%);
}

.slick-track {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 20px;
}

.slick-list {
  overflow: hidden;
}

.slick-slide {
  padding: 20px 50px !important;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(65, 65, 65, 0.3);
  clip-path: polygon(5% 0%, 100% 0, 100% 80%, 95% 100%, 0 100%, 0% 20%);
}

.slick-slide {
  background: rgba(65, 65, 65, 0.3);
}

.slick-holder {
  background: #09151a;
  padding: 30px;
}

.footer {
  background-image: url("../../assets/images/footer-bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.solution {
  background: url("../images/solution-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.differentiation {
  background: url("../images/differntiation-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left;
}

.diff-card {
  /* border: 0.5px solid #FFF; */
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.08) 0%,
    rgba(0, 0, 0, 0.2) 100%
  );
  backdrop-filter: blur(10px);
}

.secure-content {
  position: absolute;
  top: 0;
  left: 0;
  padding: 50px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.secure-img-sm {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
}

.team-modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  /* glass effect */
  backdrop-filter: blur(10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-card {
  background: #0b1314;
  border-radius: 10px;
  padding: 50px 40px;
}

.secure-future {
  background-image: url("../images/secure-bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right;
}

.api-card {
  border: 1px solid transparent;
  cursor: pointer;
  background: linear-gradient(
    180deg,
    rgba(43, 255, 255, 0.12) 0%,
    rgba(43, 255, 255, 0.04) 50%,
    rgba(43, 255, 255, 0.07) 100%
  );
  border-image-slice: 1;
  backdrop-filter: blur(84px);
}
.api-card:hover,
.api-card.active {
  border: 1px solid;
  background: linear-gradient(
    180deg,
    rgba(43, 255, 255, 0.12) 0%,
    rgba(43, 255, 255, 0.04) 50%,
    rgba(43, 255, 255, 0.07) 100%
  );
  border-image-source: linear-gradient(
    180deg,
    rgba(43, 255, 255, 0.32) 0%,
    rgba(43, 255, 255, 0.1) 50%,
    rgba(43, 255, 255, 0.16) 100%
  );
  border-image-slice: 1;
  backdrop-filter: blur(84px);
}

.pricing-card {
  background: linear-gradient(
    180deg,
    rgba(95, 142, 163, 0.1) 0%,
    rgba(11, 9, 9, 0.1) 100%
  );
}

.pricing-card-item {
  background: linear-gradient(
    164.95deg,
    rgba(43, 255, 255, 0.1) 0%,
    rgba(43, 255, 255, 0.02) 50%,
    rgba(43, 255, 255, 0.06) 100%
  );
}

.search-input {
  background: url("../images/search.png");
  background-size: 15px;
  background-position: 2%;
  background-repeat: no-repeat;
}
.published-date {
  position: absolute;
  bottom: -25px;
  left: 20px;
}
button.bg-transparent.mt-8.p-0 svg {
  max-width: 100%;
}

.overlay-glass {
  background: rgb(1 13 21 / 50%);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: absolute;
  z-index: 99999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.blog-content h1 {
  font-size: 25px;
  font-weight: bold;
  margin-bottom: 15px;
  color: white; /* White color for the heading */
}

.blog-content h2 {
  font-size: 22px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 10px;
  color: white; /* White color for subheadings */
}

.blog-content h3 {
  font-size: 18px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 8px;
  color: white; /* White color for sub-subheadings */
}

.blog-content p {
  font-size: 16px;
  line-height: 1.6;
  margin-bottom: 15px;
  color: white; /* White text color */
}

.blog-content ul,
.blog-content ol {
  margin-left: 20px;
  margin-bottom: 20px;
}

.blog-content li {
  font-size: 16px;
  margin-bottom: 8px;
  color: white;
  list-style: disc;
}

.blog-content ul li strong,
.blog-content ol li strong {
  font-weight: bold;
  color: white; /* White color for important text */
}

.blog-content a {
  color: #007bff; /* Blue links */
  text-decoration: none;
}

.blog-content a:hover {
  text-decoration: underline;
}

.blog-content blockquote {
  font-style: italic;
  border-left: 4px solid #fff; /* White border */
  padding-left: 15px;
  margin: 20px 0;
  color: white; /* White color for blockquotes */
}

.blog-content .highlight {
  background-color: #f9f9f9;
  padding: 10px;
  border: 1px dashed #ccc;
  margin: 10px 0;
}

.blog-content .footer {
  font-size: 14px;
  color: white; /* White color for footer */
  margin-top: 40px;
}

.blog-content .footer p {
  margin: 5px 0;
}

.blog-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 16px;
  text-align: left;
}

.blog-table th,
.blog-table td {
  border: 1px solid #ddd;
  padding: 8px;
}

.blog-table th {
  /* background-color: #f4f4f4; */
  font-weight: bold;
}

/* .blog-table tr:nth-child(even) {
  background-color: #f9f9f9;
} */

span.bubble {
  background: white;
  color: black;
  display: inline-block;
  margin-left: 5px;
  padding: 0px 5px;
  border-radius: 30px;
  font-size: 12px;
  opacity: 0.8;
}

@media screen and (max-width: 992px) {
  .menus a {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .menus {
    display: none;
  }
  .menus.active {
    display: flex;
  }
}

@media screen and (max-width: 767px) {
  .secure-content {
    position: unset;
  }

  .secure-img-sm {
    display: none;
  }

  .f-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  nav {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .card.pricing-card.p-5.relative {
    height: 200px;
    overflow: hidden;
  }

  .team-modal {
    position: fixed;
    top: 50%;
    left: 3px;
    z-index: 100000;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    justify-content: center;
    align-items: center;
    transform: translateY(-50%);
    height: 100vh !important;
    display: flex;
    justify-content: flex-start !important;
    align-items: flex-start !important;
  }
  br {
    display: none;
  }
  h2.text-\[32px\].text-white.font-bold {
    font-size: 24px;
  }
  img.secure-btn {
    height: 45px;
}
}
